<template>
    <v-row justify="center">
        <v-dialog v-model="dialogEdit" persistent transition="scale-transition" max-width="1200px">
            <v-card class="elevation-1">
                <ValidationObserver ref="refProduct" v-slot="{ invalid, validated }">
                    <v-form name="form" @submit.prevent="updateProducto">
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="handleModalEdit">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>Editar Producto</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn class="primary" type="submit" :disabled="invalid || !validated">
                                    <v-icon class="ma-2" dense large left>fas fa-save</v-icon>Guardar cambios
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-container fluid>
                                <v-row wrap>
                                    <v-flex d-flex md8>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="8">
                                                <ValidationProvider rules="required" v-slot="{ errors, valid }"
                                                    :immediate="true">
                                                    <v-text-field label="Nombre del producto*" prepend-icon="fas fa-poll-h"
                                                        required v-model="producto.nombre" :error-messages="errors"
                                                        :success="valid">
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <ValidationProvider rules="required|min:3|max:5" v-slot="{ errors, valid }" :immediate="true">
                                                    <v-text-field label="Clave*" prepend-icon="fas fa-key"
                                                        v-model="producto.clave" :error-messages="errors" :success="valid">
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="2" sm="4" md="4">
                                                <v-color-picker v-model="producto.color" dot-size="24" hide-canvas
                                                    hide-mode-switch mode="hexa" swatches-max-height="180">
                                                </v-color-picker>
                                            </v-col>
                                            <v-col cols="4" sm="8" md="8">
                                                <v-textarea label="Descripcion" class="mx-2" rows="2" filled
                                                    hint="Descripción del producto" prepend-icon="fas fa-align-left" persistent-hint
                                                    v-model="producto.descripcion">
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-flex>

                                    <v-flex d-flex md4>
                                        <v-row class="space-between align-center ml-auto mt-0">
                                            <v-col cols="12" sm="12" md="12">
                                                <v-card class="mx-auto">
                                                    <v-img :src="url" height="60" class="grey darken-4" aspect-ratio="1.7"
                                                        contain>
                                                    </v-img>
                                                    <v-card-title class="title">
                                                        <v-file-input show-size label="Seleccionar una imagen"
                                                            @change="selectFile" prepend-icon="fas fa-camera-retro"
                                                            :rules="rules" accept="image/png, image/jpeg, image/bmp">
                                                        </v-file-input>
                                                    </v-card-title>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-flex>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="4" class="mt-n8">
                                        <v-checkbox v-model="producto.isServicio" label="¿Es un servicio?" color="success" hide-details></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4" class="mt-n8" v-if="producto.isServicio">
                                        <ValidationProvider :rules="producto.isServicio ? 'required|min_value:1' : ''" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field label="Precio de servicio*" type="number" hint="Campo obligatorio solo si es un servicio.." prepend-icon="fas fa-layer-group"
                                                persistent-hint required v-model="producto.amountSell" min="1" :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                        <ValidationProvider rules="required|min:1|numeric" v-slot="{ errors, valid }"
                                            :immediate="true">
                                            <v-text-field label="Stock minimo*" type="number" hint="Campo obligatorio.."
                                                prepend-icon="fas fa-layer-group" persistent-hint required
                                                v-model="producto.minStock" min="1" :error-messages="errors"
                                                :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <ValidationProvider rules="required|min:1|numeric" v-slot="{ errors, valid }"
                                            :immediate="true">
                                            <v-text-field class="mx-2" label="Stock maximo*" type="number"
                                                prepend-icon="fas fa-layer-group" v-model="producto.maxStock"
                                                min="1" :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <ValidationProvider rules="required" v-slot="{ errors, valid }" :immediate="true">
                                            <v-autocomplete :items="unidades" item-text="nombre" item-value="id"
                                                v-model="producto.idTipoUnidad" label="Tipo Unidad de venta*" required
                                                prepend-icon="fas fa-poll-h" :error-messages="errors"
                                                :success="valid">
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <span class="text-bold">* Indica son campos requeridos</span>
                            </v-container>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import ProductoDataService from '@/service/producto/ProductoDataService';
import Camera from '@/assets/camera.png'
export default {
    name: "EditarProducto",
    props: {
        dialogEdit: { type: Boolean, default: false },
        handleModalEdit: { type: Function },
        producto: { type: Object, default: () => ({}) },
        initialize: { type: Function },
        unidades: { type: Array }
    },
    created() {
        this.url = this.producto.imagen != null ? `${this.$store.state.auth.rutaFichero}producto/${this.producto.imagen}` : Camera;
    },
    data() {
        return {
            rules: [
                value => !value || value.size < 2000000 || 'La foto debe ser menos de 2 MB!'
            ],
            url: Camera,
            currentFile: undefined,
            progress: 0,
        }
    },
    methods: {
        updateProducto() {
            ProductoDataService.update(this.producto).then(resp => resp ).then( response => {
                if( response.status == 200 ) {
                    if( this.currentFile != undefined ) {
                        const productId = response.data.id
                        ProductoDataService.updatePhoto(this.currentFile, productId).then(resp => {
                            this.initialize();
                            this.handleModalEdit();
                            this.$swal.fire({ icon: 'success', title: 'Producto actualizado correctamente', text: resp.data.message, showConfirmButton: false, timer: 3500 });
                        }).catch( error => {
                            this.$swal.fire({ icon: 'error', title: 'Error al actualizar el producto, verificar la información', html: error.response.data, showConfirmButton: false, timer: 3500 });
                        })
                    } else {
                        this.$swal.fire({ icon: 'success', title: 'Producto actualizado correctamente', text: response.data.message, showConfirmButton: false, timer: 3500 });
                        this.handleModalEdit();
                        this.initialize();
                    }
                }
            }).catch( error => {
                this.$swal.fire({ icon: 'error', title: 'Error al actualizar el Producto', html: error, showConfirmButton: false, timer: 3500 });
            })
        },
        selectFile(file) {
            this.progress = 0;
            this.currentFile = file;
            if (this.currentFile != null) {
                this.url = URL.createObjectURL(this.currentFile);
            }
        }
    },
}
</script>