<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1200px">
            <v-card class="elevation-1">
                <ValidationObserver ref="obsProduct" v-slot="{ invalid, validated }">
                    <v-form name="form" @submit.prevent="saveProducto">
                        <v-toolbar dark color="primary">
                            <v-btn icon dark @click="handleModal">
                                <v-icon>fas fa-times-circle</v-icon>
                            </v-btn>
                            <v-toolbar-title>Nuevo Producto</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                                <v-btn class="primary" type="submit" :disabled="invalid || !validated">
                                    <v-icon class="ma-2" dense medium left>fas fa-save</v-icon>Guardar producto
                                </v-btn>
                            </v-toolbar-items>
                        </v-toolbar>
                        <v-card-text>
                            <v-container fluid>
                                <v-row wrap>
                                    <v-flex d-flex md8>
                                        <v-row>
                                            <v-col cols="12" sm="6" md="8">
                                                <ValidationProvider rules="required" v-slot="{ errors, valid}">
                                                    <v-text-field label="Nombre del producto*" prepend-icon="fas fa-poll-h"
                                                    required clearable v-model="producto.nombre" :counter="100"
                                                    :error-messages="errors" :success="valid">
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" sm="6" md="4">
                                                <ValidationProvider rules="required|min:3|max:5" v-slot="{ errors, valid}">
                                                    <v-text-field label="Clave*" prepend-icon="fas fa-key" required clearable hint="Acceso directo del producto"
                                                    v-model="producto.clave" :counter="5" :error-messages="errors" :success="valid">
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" sm="4" md="4">
                                                <v-color-picker v-model="producto.color" dot-size="24" hide-canvas
                                                hide-mode-switch mode="hexa" swatches-max-height="180">
                                                </v-color-picker>
                                            </v-col>
                                            <v-col cols="12" sm="8" md="8">
                                                <v-textarea label="Descripcion" class="mx-2" rows="2" filled
                                                hint="Descripción del producto" prepend-icon="fas fa-align-left"
                                                persistent-hint v-model="producto.descripcion" counter>
                                                </v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-flex>

                                    <v-flex d-flex md4>
                                        <v-row class="space-between align-center ml-auto">
                                            <!-- <div class="d-flex flex-column"> -->
                                                <v-col cols="12" sm="12" md="12">
                                                    <v-card class="mx-auto">
                                                        <v-img :src="url" height="60"
                                                            class="grey darken-4"
                                                            aspect-ratio="1.7" contain>
                                                        </v-img>
                                                        <v-card-title class="title">
                                                            <v-file-input show-size label="Cargar imagen del producto" @change="selectFile" 
                                                            prepend-icon="fas fa-camera-retro" :rules="rules" accept="image/png, image/jpeg, image/bmp">
                                                            </v-file-input>
                                                        </v-card-title>
                                                    </v-card>
                                                </v-col>
                                            <!-- </div> -->
                                        </v-row>
                                    </v-flex>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="4" class="mt-n8">
                                        <v-checkbox v-model="producto.isServicio" label="¿Es un servicio?" color="success" hide-details></v-checkbox>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4" class="mt-n8" v-if="producto.isServicio">
                                        <ValidationProvider :rules="producto.isServicio ? 'required|min_value:1' : ''" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field label="Precio de servicio*" type="number" hint="Campo obligatorio solo si es un servicio.." prepend-icon="fas fa-layer-group"
                                                persistent-hint required v-model="producto.amountSell" min="1" :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" sm="6" md="4">
                                        <ValidationProvider rules="required|min_value:1" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field label="Stock minimo*" type="number" hint="Campo obligatorio.." prepend-icon="fas fa-layer-group"
                                                persistent-hint required v-model="producto.minStock" min="1" :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                
                                    <v-col cols="12" sm="6" md="4">
                                        <ValidationProvider rules="required|min_value:1" v-slot="{ errors, valid}" :immediate="true">
                                            <v-text-field class="mx-2" label="Stock maximo*" type="number" prepend-icon="fas fa-cubes"
                                                v-model="producto.maxStock" min="1" :error-messages="errors" :success="valid">
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    
                                    <v-col cols="12" sm="6" md="4">
                                        <ValidationProvider mode="eager" rules="required" inmediate v-slot="{ errors, valid}" :immediate="true">
                                            <v-autocomplete :items="unidades" item-text="nombre" item-value="id" 
                                                v-model="producto.idTipoUnidad" label="Tipo Unidad venta*" required clearable prepend-icon="fas fa-poll-h"
                                                :error-messages="errors" :success="valid">
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>                            
                                </v-row>
                                <small>* Indica son campos requeridos</small>
                            </v-container>
                        </v-card-text>
                    </v-form>
                </ValidationObserver>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import ProductoDataService from '@/service/producto/ProductoDataService';
import Camera from '@/assets/camera.png'

export default {
    name: "NuevoProducto",
    props: {
        dialog: { default: false, type: Boolean },
        unidades: { type: Array },
        handleModal: { type: Function },
        initialize: { type: Function }
    },
    data () {
        return {
            producto: {
                nombre:         '',
                clave:          '',
                color:          '',
                descripcion:    '',
                profit:         0.00,
                minStock:       0,
                maxStock:       0,
                idTipoUnidad:   1,
                isServicio:     false,
                amountSell:     0.00,
            },
            url         : Camera,
            photo       : undefined,
            progress    : 0,
            fileInfos   : [],
            rules: [
                value => !value || value.size < 2000000 || 'La foto debe ser menos de 2 MB!'
            ],
        }
    },
    methods: {
        saveProducto(){
            this.$refs.obsProduct.validate().then( isValid => {
                if ( isValid ) {
                    ProductoDataService.store( this.producto)
                    .then( resp => { return resp }).then( response => {
                        if( response.status == 200 ) {
                            if( this.photo != undefined ){
                                const productId = response.data.id
                                ProductoDataService.uploadPhoto( this.photo, productId ).then( resp => {
                                    this.$refs.obsProduct.reset();
                                    this.handleModal();
                                    this.initialize()
                                    this.$swal.fire({ icon: 'success', title: resp.data.message, showConfirmButton: false, timer: 2000 })
                                }).catch( err => {
                                    this.$swal.fire({ icon:'error', title: 'Error al guardar el producto', html: err.response.data, showConfirmButton: false, timer: 2000 });
                                });
                            } else {
                                this.$refs.obsProduct.reset();
                                this.handleModal();
                                this.initialize()
                                this.$swal.fire({ icon: 'success', title: 'Producto guardado correctamente', showConfirmButton: false, timer: 2000 })
                            }
                        }
                    }).catch( e => {
                        this.$swal.fire({ icon:'error', title: 'Por favor, llene todos los campos requeridos!', html: e.response.data, showConfirmButton: false, timer: 2000 });
                    })
                }
            }).catch( e => {
                this.$swal.fire({ icon:'error', title: 'Por favor, llene todos los campos requeridos!', text: e.message, showConfirmButton: false, timer: 2000 });
            })
        },
        calculateTotal(cantidad, precio, descuento){
            let precioTotal = cantidad * precio;
            this.producto.totalDescuento = (precioTotal / 100) * descuento;
            this.producto.total = (precioTotal - this.producto.totalDescuento);
        },
        selectFile(file){
            this.progress = 0;
            this.photo = file;
            if (this.photo != null) {
                this.url = URL.createObjectURL(this.photo);
            }
        }
    },
    watch: {
        'producto.isServicio': function (val){
            this.producto.minStock = 0;
            this.producto.maxStock = 0;
            if (val == true) {
                this.producto.minStock = 1;
                this.producto.maxStock = 1;
            }
        },
        'producto.precioUnitario': function (val){
            if ( val != null && val > 0 && this.producto.cantidad > 0) {
                if (this.producto.descuentoPorcentaje > 0) {
                    this.calculateTotal( this.producto.cantidad, val, this.producto.descuentoPorcentaje);
                } else {
                    this.calculateTotal( this.producto.cantidad, val, 0);
                }
            } else {
                this.producto.precioUnitario = 0;
                this.producto.descuentoPorcentaje= 0;
                this.calculateTotal( this.producto.cantidad, this.producto.precioUnitario, this.producto.descuentoPorcentaje);
            }
        },
        'producto.descuentoPorcentaje': function (val){
            if (val != null) {
                if ( this.producto.cantidad > 0 && this.producto.precioUnitario > 0) {
                    this.calculateTotal( this.producto.cantidad, this.producto.precioUnitario, val);
                }
            } else {
                if (this.producto.precioUnitario > 0) {
                    this.calculateTotal( this.producto.cantidad, this.producto.precioUnitario, 0);
                } 
            }
        }
    },
}
</script>
