<template>
    <v-row align="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="cyan darken-4" @click="dialog = true">
                            <v-icon left>fas fa-plus-square</v-icon>Nuevo producto
                        </v-btn>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-btn small dark color="cyan darken-4"
                            @click="mostrarInactivos = !mostrarInactivos">
                            <v-icon left>fas fa-tag</v-icon>{{ mostrarInactivos ? 'Productos Activos' : 'Productos Inactivos' }}
                        </v-btn>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-col cols="12" md="6" sm="12" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search" label="Buscar producto" single-inline hide-details @keydown.enter="searchProducto"
                        placeholder="Buscar por nombre, clave o descripción">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>
                <v-data-table dense :headers="filteredHeaders" :items="filterByStatus" class="elevation-1"
                :loading="loading" :options.sync="options"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ] }"
                :server-items-length="totalProducto">
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="show(item)" v-if="!item.eliminado">fas fa-edit</v-icon>
                        <v-icon small class="mr-2" @click="remove(item)" v-if="!item.eliminado">fas fa-trash-alt</v-icon>
                        <v-icon small class="mr-2" @click="restore(item)" v-if="item.eliminado">fas fa-undo</v-icon>
                    </template>
                    <template v-slot:[`item.imagen`]="{ item }">
                        <v-img :lazy-src="generateUrlImage(item.imagen)"
                        :src="generateUrlImage(item.imagen)"
                        max-width="50" max-height="50"></v-img>
                    </template>
                    <template v-slot:[`item.eliminado`]="{ item }">
                        <v-chip :color="colorStatus(item.eliminado)" label text-color="white" x-small>
                            <v-icon x-small left color="white">fas fa-tag</v-icon>
                            {{ item.eliminado ? 'Inactivo' : 'Activo' }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.isServicio`]="{ item }">
                        <v-chip color="teal lighten-1" label text-color="white" x-small>
                            <v-icon x-small left color="white">fas fa-tag</v-icon>
                            {{ item.isServicio ? 'Servicio' : 'Producto' }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.amountSell`]="{ item }">
                        <v-chip color="teal lighten-4" label text-color="white" x-small>
                            <v-icon x-small left color="white">fas fa-tag</v-icon>
                            {{ item.amountSell == 0 ? 'Precios variables por cliente' : item.amountSell }}
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
        <NuevoProduct :unidades.sync="unidades" :dialog.sync="dialog" :initialize.sync="initTableProducto"
        :handleModal="handleModal" v-if="dialog"/>
        <EditProduct :unidades.sync="unidades"  :producto.sync="infoProducto"  :dialogEdit.sync="dialogEdit" :initialize.sync="initTableProducto" 
        :handleModalEdit="handleModalEdit" v-if="dialogEdit"/>
    </v-row>
</template>
<script>
import ProductoDataService from "@/service/producto/ProductoDataService";
import UnidadSerivce from "@/service/catalogo/tipoUnidad/tipoUnidadService";
import NuevoProduct from "./NuevoProducto.vue";
import EditProduct from "./EditarProducto.vue";
import { getParamsPagination } from "@/utils/pagination";
import Camera from '@/assets/camera.png'
import Columns from "@/components/shared/Columns.vue";
export default {
    name: "ListaProducto",
    components: { NuevoProduct, EditProduct, Columns },
    created() {
        this.initTableProducto();
        this.getUnidad();
    },
    data() {
        return {
            unidades: [],
            productos: [],
            options: {},
            totalProducto: 0,
            loading: false,
            search: "",
            mostrarInactivos: false,

            dialog: false,
            dialogEdit: false,
            infoProducto: [],
            headers: [
                { text: "Acciones", value: "actions", sortable: false, groupable: false, show: true },
                { text: "ID", align: " d-none", sortable: false, value: "id", visible: false, groupable: false, show: false },
                { text: "Clave", value: "clave", sortable: false, groupable: false, show: true },
                { text: "Nombre", value: "nombre", sortable: false, groupable: false, show: true },
                { text: 'Imagen', value: 'imagen', sortable: false, groupable: false, show: true },
                { text: 'Descripción', value: 'descripcion', sortable: false, groupable: false, show: true },
                { text: 'Unidad', value: 'tipoUnidad.nombre', sortable: false, groupable: false, show: true },
                { text: 'Tipo', value: 'isServicio', sortable: false, groupable: false, show: true },
                { text: 'Precio servicio', value: 'amountSell', sortable: false, groupable: false, show: true },
                { text: 'Stock minimo', value: 'minStock', sortable: false, groupable: false, show: true },
                { text: 'Stock maximo', value: 'maxStock', sortable: false, groupable: false, show: true },
                // { text: 'Precio venta', value: 'precioVentaNatural', sortable: false, groupable: false, show: true },
                { text: 'Estatus', value: 'eliminado', sortable: false, groupable: false, show: true },
            ]
        }
    },
    methods: {
        searchProducto() {
            this.options.page = 1;
            this.initTableProducto(this.options);
        },
        initTableProducto( options = {} ) {
            this.loading = true;
            const params = getParamsPagination({ options, search: this.search });
            ProductoDataService.listTable(params).then(res => {
                const { rows, totalElements } = res.data;
                this.productos = rows;
                this.totalProducto = totalElements;
            }).catch(err => {
                this.$swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data.message })
            }).finally(() => {
                this.loading = false;
            })
        },

        show(item) {
            ProductoDataService.show(item.id).then(res => {
                if (res.status == 200) {
                    this.infoProducto = res.data;
                    this.dialogEdit = true;
                }
            }).catch(err => {
                this.$swal.fire({ icon: 'error', title: 'Oops...', html: err.response.data })
            });
        },
        remove(item) {
            this.$swal.fire({
                title: "Estas seguro de quitar el producto?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Si, eliminar!"
            }).then(result => {
                if (result.isConfirmed) {
                    ProductoDataService.delete(item.id).then(_ => {
                        this.initTableProducto();
                        this.$swal.fire({ icon: 'success', title: 'Desactivado!', showConfirmButton: false, timer: 1500 })
                    }).catch(error => {
                        this.$swal.fire({ icon: 'error', title: 'Oops...', html: error.response.data, showConfirmButton: false, timer: 1500 })
                    });
                }
            });
        },
        restore(item) {
            this.$swal.fire({
                title: "Reactivar producto?",
                icon: "question",
                showCancelButton: true,
                cancelButtonColor: "#d33"
            }).then(result => {
                if (result.isConfirmed) {
                    ProductoDataService.restore(item.id).then( _ => {
                        this.$swal.fire({ icon: 'success', title: 'Reactivado', text: 'Producto reactivado', showConfirmButton: false, timer: 2200 })
                        this.initTableProducto();
                    }).catch( error => {
                        this.$swal.fire({ icon: 'error', title: 'Oops...', text: error.response.data, showConfirmButton: false, timer: 2200 })
                    });
                }
            }).catch(err => {
                this.$swal.fire({ icon: 'error', title: 'Oops...', text: err.response.data, showConfirmButton: false, timer: 2200 })
            });
        },
        colorStatus(status) { return status ? 'red darken-1' : 'teal darken-2'; },
        generateUrlImage( image ){
            return image != null ? `${this.$store.state.auth.rutaFichero}producto/${image}` : Camera;
        },
        handleModal() { this.dialog = !this.dialog; },
        handleModalEdit() { this.dialogEdit = !this.dialogEdit; },
        async getUnidad(){
            this.unidades = await UnidadSerivce.getAllUnidad().then( resp => resp.data );
        },
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTableProducto(val);
                }
            }, deep: true
        }
    },
    computed: {
        filteredHeaders() { return this.headers.filter(h => h.show); },
        filterByStatus() {
            return this.mostrarInactivos
            ? this.productos.filter(producto => producto.eliminado)
            : this.productos.filter(producto => !producto.eliminado)
        }
    }
};
</script>